import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignUpConfig from '../main/sign-up/SignUpConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import NewPasswordConfig from '../main/new-password/NewPasswordConfig';
import Error404Page from '../main/404/Error404Page';
import ProfileViewConfig from '../main/profileview/ProfileViewConfig';
import PublicProfileConfig from '../main/publicprofile/PublicProfileConfig';
import PublicPostConfig from '../main/publicpost/PublicPostConfig';
import PostViewConfig from '../main/postview/PostViewConfig';
import ChatPostViewConfig from '../main/chatpostview/ChatPostViewConfig';
import ConfirmSignupConfig from '../main/confirm-signup/ConfirmSignupConfig';
import SearchProvidersConfig from '../main/searchproviders/SearchProvidersConfig';
import PublicSearchProvidersConfig from '../main/searchproviders/PublicSearchProvidersConfig';
import ChannelProfileAppConfig from '../main/channel-profile/ChannelProfileAppConfig';
import PublicChannelProfileConfig from '../main/channel-profile/PublicChannelProfileConfig';
import ResetPasswordConfig from '../main/reset-password/ResetPasswordConfig';
import ForgotPasswordConfig from '../main/forgot-password/ForgotPasswordConfig';
import ResendConfirmationConfig from '../main/resend-confirmation/ResendConfirmationConfig';
import PublicOrgProfileConfig from '../main/org-profile/PublicOrgProfileConfig';
import OrgProfileAppConfig from '../main/org-profile/OrgProfileAppConfig';
import FindCareConfig from '../main/find-care/FindCareConfig';
import PublicFindCareConfig from '../main/find-care/PublicFindCareConfig';
import CheckoutAppConfig from '../main/apps/checkout/CheckoutAppConfig';
//import VideoAppConfig from '../main/video/VideoAppConfig';
import appsConfigs from '../main/apps/appsConfigs';
import pagesConfigs from '../main/pages/pagesConfigs';
import marketingConfigs from '../main/marketing/marketingConfig';


const routeConfigs = [...appsConfigs, ...pagesConfigs, ProfileViewConfig, PublicProfileConfig, SearchProvidersConfig, PublicSearchProvidersConfig,
                      PublicPostConfig, PostViewConfig, ChatPostViewConfig, SignOutConfig, SignInConfig, SignUpConfig,
                      ConfirmSignupConfig, NewPasswordConfig, ChannelProfileAppConfig, ResendConfirmationConfig,
                      PublicChannelProfileConfig, ResetPasswordConfig, ForgotPasswordConfig,
                      PublicOrgProfileConfig, OrgProfileAppConfig, FindCareConfig, PublicFindCareConfig, CheckoutAppConfig,
                      ...marketingConfigs];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '*',
    element: <Navigate to="404" />,
  },
];

export default routes;
