import * as React from 'react';
import usePrevious from '@fuse/hooks/usePrevious';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import { getGroups } from 'app/store/groupsSlice';
import { fetchClients } from 'app/store/clientsSlice';
import { getConnections } from 'app/store/connectionsSlice';
import { subscribeToConnections } from 'app/store/subscriptionSlice';
import { getExternalMessages } from 'app/store/externalMessageSlice';
import { fetchChannel } from 'app/store/channelsSlice';
import { fetchSystemConfig } from 'app/store/homeSlice';
import { fetchReferrals } from 'app/store/referralsSlice';
import { fetchInterests } from 'app/store/referralInterestSlice';
import { fetchAuthorizedClients } from 'app/store/clientsAuthorizedSlice';
import { bulkLoginCalls } from 'app/shared-components/util/bulkApi';
import { warningMessage, clearMessage } from 'app/shared-components/util/message';
import { selectUser, setUserInitialized, fetchSubscriptionStatus } from 'app/store/userSlice';
import { selectConnectionStatus, checkConnectionStatus, selectPreviousConnectionStatus } from 'app/store/subscriptionSlice';
import { useSnackbar } from 'notistack';
import { useAuth } from './auth/AuthContext';
import useInterval from 'app/shared-components/util/useInterval';



function AppInitialization({ children }) {
  const dispatch = useDispatch();
  const user= useSelector(selectUser);
  const connectionStatus= useSelector(selectConnectionStatus);
  const previousStatus= useSelector(selectPreviousConnectionStatus);
  const userId= user?.id;
  const isAuthenticated= useAuth().isAuthenticated;
  const isInitialized= user?.initialized;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const statusInterval= 15000; //15 seconds (very lightweight call)
  const [startCheck, stopCheck]= useInterval(checkStatus, statusInterval);

  function checkStatus(){
     dispatch(checkConnectionStatus());
  }

  useEffect(() => {
    if (previousStatus !== "Connected" && connectionStatus === "Connected"){
    	 clearMessage(dispatch);
    }
    else if (previousStatus === "Connected" && connectionStatus && connectionStatus !== "Connected"){
    	 warningMessage(dispatch, "App is disconnected; please check your network.", 60000);
    }
  }, [connectionStatus]);
   
  useEffect(() => {
  	if (!isAuthenticated) stopCheck();
  	
   	if (isAuthenticated && !isInitialized)
	    //Use bulk load to get required data
      bulkLoginCalls({user: user}).then((bulk) => {
				 const bulkData= bulk.items;
				 const subscriptionStatus= bulkData['subscription'];
				 const userTimeline= bulkData['userTimeline'];
				 const systemConfig= bulkData['systemConfig'];
				 const groups= bulkData['groups'];
				 const teams= bulkData['teams'];
				 const organizations= bulkData['organizations'];
				 const clients= bulkData['clients'];
				 const connections= bulkData['connections'];
				 const messages= bulkData['messages'];
				 const referrals= bulkData['referrals'];
				 const referralInterests= bulkData['referralInterests'];
				 const authorizedClients= bulkData['authorizedClients'];

	     Promise.all([
          dispatch(fetchChannel({channelId: user.timelineId, bulk: userTimeline.code == 200 ? userTimeline.body : undefined })),
          dispatch(fetchSystemConfig({bulk: systemConfig.code == 200 ? systemConfig.body : undefined })),
	        dispatch(getGroups({type: "group", bulk: groups.code == 200 ? groups.body : undefined })),
	        dispatch(getGroups({type: "team", closeSnackbar: closeSnackbar, enqueueSnackbar: enqueueSnackbar, bulk: teams.code == 200 ? teams.body : undefined})),
	  	    dispatch(getGroups({type: "organization", closeSnackbar: closeSnackbar, enqueueSnackbar: enqueueSnackbar, bulk: organizations.code == 200 ? organizations.body : undefined})),
	        dispatch(fetchClients({ bulk: clients.code == 200 ? clients.body : undefined })), 	
	        dispatch(getConnections({ bulk: connections.code == 200 ? connections.body : undefined })),
	        dispatch(getExternalMessages({ bulk: messages.code == 200 ? messages.body : undefined })),
	        dispatch(fetchReferrals({ status: "open", bulk: referrals.code == 200 ? referrals.body : undefined })),
	        dispatch(fetchInterests({ status: "open", bulk: referralInterests.code == 200 ? referralInterests.body : undefined })),
	        dispatch(fetchAuthorizedClients({ bulk: authorizedClients.code == 200 ? authorizedClients.body : undefined })),
	        dispatch(fetchSubscriptionStatus({ bulk: subscriptionStatus?.code == 200 ? subscriptionStatus.body : undefined })),
	        dispatch(subscribeToConnections()),
	        // Receive data in here before app initialization
	      ]).then((values) => {
	        dispatch(setUserInitialized());
	        startCheck();
	      });	
	  });	  
   }, [userId, isAuthenticated]);
 
  return !isAuthenticated || isInitialized ? (
	children
  ) : (
    <FuseSplashScreen />
  );
}


export default AppInitialization;
