import { Suspense } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import clsx from 'clsx';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectFuseCurrentLayoutConfig, selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import { selectFuseNavbar } from 'app/store/fuse/navbarSlice';
import { selectUser } from 'app/store/userSlice';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import Logo from 'app/theme-layouts/shared-components/Logo';
import BrandName from 'app/theme-layouts/shared-components/BrandName';
import DisconnectedIcon from 'app/theme-layouts/shared-components/DisconnectedIcon';
import NavbarToggleButton from '../../shared-components/NavbarToggleButton';
import ProviderToolbar from './ProviderToolbar';
import GuestToolbar from './GuestToolbar';
import UserToolbar from './UserToolbar';

function ToolbarLayout1(props) {
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const navbar = useSelector(selectFuseNavbar);
  const toolbarTheme = useSelector(selectToolbarTheme)
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('md'));
  const user = useSelector(selectUser);
  const isGuest= !user.role || user.role.length === 0;
  const isProvider = !isGuest && user.data?.type === 'provider';
  const homeTarget= isGuest ? '/' : '/home';

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx('flex relative z-20 shadow-md', props.className)}
        color="default"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? toolbarTheme.palette.background.paper
              : toolbarTheme.palette.background.default,
        }}
        position="static"
      >
        <Toolbar className="p-0 min-h-48 md:min-h-64">
          <Suspense>
          <div className="flex flex-1 px-16">
            {config.navbar.display && config.navbar.position === 'left' && (
              <>
                <Hidden lgDown>
                  {(config.navbar.style === 'style-3' ||
                    config.navbar.style === 'style-3-dense') && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}

                  {config.navbar.style === 'style-1' && !navbar.open && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
                
                <NavLinkAdapter to={homeTarget}>
                	<Logo/>
                </NavLinkAdapter>
                
                <Hidden smDown>
                  <BrandName target={homeTarget}/>
                </Hidden>
                
                <DisconnectedIcon />

              </>
            )}

            {config.navbar.display && config.navbar.position === 'right' && (
              <>
                <NavLinkAdapter to={homeTarget}>
                  <Logo/>
                </NavLinkAdapter>

                <BrandName target={homeTarget}/>
              </>
            )}
            
           {!config.navbar.display  && (
              <>
                <Logo/>
                
                <Hidden smDown>
                  <BrandName/>
                </Hidden>
              </>
            )}

          </div>

          <div className="flex items-center px-8 h-full overflow-x-auto">
      
			{isProvider && <ProviderToolbar user={user} isMobile={isMobile} />}
			
			{isGuest && <GuestToolbar user={user} isMobile={isMobile} />}
			
			{!isProvider && !isGuest && <UserToolbar user={user} isMobile={isMobile} />}

          </div>

          {config.navbar.display && config.navbar.position === 'right' && (
            <>
              {/* <Hidden lgDown>
                {!navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />}
              </Hidden> */}

              {/* <Hidden lgUp> */}
                <div className="mx-8"><NavbarToggleButton className="w-40 h-40 p-0" /></div>
              {/* </Hidden> */}
            </>
          )}
          </Suspense>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}

export default memo(ToolbarLayout1);
