import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import history from '@history';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';


function UserAvatar(props) {
  let { user, className, sx, disableClick, mode, unread} = props;
  if (!user) return null;
  const isPublic= mode == "public";
  if (user.raw) user= user.raw;
  const userName= user.firstName ? user.firstName : 'Guest';
  const isBot= user.id && user.id.includes("AI-BOT");

  
  const navigateToUser= (event) => {
	  if (!disableClick && user.screenName){
		 const pathToUser= (isPublic ? "/public" : "") + "/profile/" + user.screenName;
	  	 history.push({
		    pathname: pathToUser,
		 }); 
		 event.stopPropagation();
     event.preventDefault();
	  } 
  }
  
  return (
	  <div onClick={navigateToUser}>
	    <Badge color="secondary" variant="dot" invisible={!unread}>         
		  <Avatar 
	              sx={sx}
	              className={className}
	              src={user.profileImageUrl}
	              alt={userName.charAt(0)}
	            >
		  	        {isBot && <AutoAwesomeIcon color="secondary" />}
	              {!isBot && userName.charAt(0)}
	      </Avatar>
	    </Badge> 
      </div>
  );
  
}

 


export default UserAvatar;