import * as Sentry from "@sentry/react";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { successMessage } from 'app/shared-components/util/message';
import { fetchChannel } from 'app/store/channelsSlice';
import { updateUserTags } from 'app/store/contactsSlice';
import { startLoading, stopLoading } from 'app/store/utilSlice';
import settingsConfig from 'app/configs/settingsConfig';
import jwtService from '../auth/services/jwtService';
import { followUser, unfollowUser, addContactToConnections, removeConnection } from './contactsSlice';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import { updateUserAttributes, confirmUserAttribute, updateMFAPreference } from 'aws-amplify/auth';



export const setUser = createAsyncThunk('user/setUser', async (user, { dispatch, getState, extra }) => {
  
  /*
    You can redirect the logged-in user to a specific route depending on his role
    */
  if (user.loginRedirectUrl) {
    settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example 'apps/academy'
  }
  
  const subManager= extra;
  subManager.init();
  console.log("Setting user!" );
  console.log(user.data);
  
  //Sentry user
  Sentry.getGlobalScope().setUser({ id: user.id, username: user.data.screenName, email: user.data.email });
  
  return user;
});

export const updateUserPhone = createAsyncThunk(
  'user/updateUserPhone',
  async (phone, { dispatch, getState }) => {
     const result= await updateUserAttributes({ userAttributes: {phone_number: phone} });
     //Disable MFA after phone change
     dispatch(updateUserMFA(false));
     return phone;
  }
);

export const verifyUserPhone = createAsyncThunk(
  'user/verifyUserPhone',
  async (code, { dispatch, getState }) => {
     const result= await confirmUserAttribute({userAttributeKey: 'phone_number', confirmationCode: code});
     return result;
  }
);

export const updateUserMFA = createAsyncThunk(
  'user/updateUserMFA',
  async (enabled, { dispatch, getState }) => {
     const status= enabled ? 'PREFERRED' : 'DISABLED';
     const result= await updateMFAPreference({sms: status})
     return enabled;
  }
);

export const updateUserSettings = createAsyncThunk(
  'user/updateSettings',
  async (settings, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = _.merge({}, user, { data: { settings } });

    dispatch(updateUserData(newUser));

    return newUser;
  }
);

export const updateUserShortcuts = createAsyncThunk(
  'user/updateShortucts',
  async (shortcuts, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = {
      ...user,
      shortcuts: shortcuts,
    };

    dispatch(updateUserData(newUser));

    return newUser;
  }
);


export const updateUserInfo = createAsyncThunk(
  'user/updateUserInfo',
  async (params, { dispatch, getState }) => {
    console.log("in updateUserInfo");

    //Get the current user state
    const { user } = getState();
    
    //Update the user data on the backend
    try{
	    let newUserObj= await updateUserInfoAPI(user.id, params);
		
		//Create new user state for the redux store
	    const newUser = {
	      ...user,
	      data: {
	        ...user.data,
	        ...params,
	      },
	      raw: newUserObj,
	    };
	    
	    console.log(newUser);

		//Return the new user state
	    return newUser;
	}finally{
		dispatch(stopLoading());
	}
  }
);

export const updateAcceptedTerms = createAsyncThunk(
  'user/updateAcceptedTerms',
  async (params, { dispatch, getState }) => {
    console.log("in updateAcceptedTerms");

    //Get the current user state
    const { user } = getState();
    
    //Update the user data on the backend
    try{
      const data= {lastAcceptedTerms: Date.now()}
	    let newUserObj= await updateUserInfoAPI(user.id, data);

		  //Return the new user state
	    return newUserObj;
	  }finally{
		  dispatch(stopLoading());
	  }
  }
);

export const updateProviderInfo = createAsyncThunk(
  'user/updateProviderInfo',
  async (params, { dispatch, getState }) => {
    console.log("updateProviderInfo, got params: " + params);

    //Get the current user state
    const { user } = getState();
    
    const newProviderInfo= { ...user.providerInfo,  ...params };

    //Create new user state for the redux store
    const newUser = {
      ...user,
      providerInfo: newProviderInfo,
      raw: {
		  ...user.raw,
		  providerInfo: newProviderInfo,
	  }
    };
    
    console.log(newUser);
    
    //Update the user data on the backend
    try{
	    let newProfileObj= await updateProviderInfoAPI(user.id, newUser.providerInfo);

		  //Return the new user state
	    return newUser;
	}finally{
		dispatch(stopLoading());
	}
  }
);

export const refreshUserInfo = createAsyncThunk(
  'user/refreshUserInfo',
  async (params, { dispatch, getState }) => {
    console.log("refreshUserInfo");

    //Get the current user state
    const { user } = getState();
    
    //Update the user data on the backend
    try{
	    let newUserInfo= await fetchUserInfoAPI(user.id);

       //Create new user state for the redux store
      const newUser = {
        ...user,
        providerInfo: {...newUserInfo.providerInfo},
        raw: {...newUserInfo },
      };
      
      console.log(newUser);

		  //Return the new user state
	    return newUser;
	}finally{
		dispatch(stopLoading());
	}
  }
);

export const updateUserImage = createAsyncThunk(
  'user/updateImage',
  async (params, { dispatch, getState }) => {
	const { fileId, imageType } = params;
    console.log("updateUserProfileImage, got fileId: " + fileId);

    //Get the current user state
    const { user } = getState();

    //Create new user state for the redux store with new fileId set
    const newUser = {
      ...user,
      images: {
        ...user.images,
      }
    };
    
    //Update the user data on the backend and get the updated URL
    try{
	    let newUserObj= await jwtService.updateUserImage(user.id, fileId, imageType);
	    newUser.raw= newUserObj;
	    switch(imageType) {
		  case 'user_profile':
			newUser.images.photoURL= newUserObj.profileImageUrl;
		    break;
		  case 'user_header':
		    newUser.images.headerImageURL= newUserObj.headerImageUrl;
		    break;
		  default:
		}
	    successMessage(dispatch, 'Updated user image.');

		//Return the new user state
	    return newUser;
	}finally{
		dispatch(stopLoading());
	}
  }
);

export const fetchSubscriptionStatus = createAsyncThunk(
  'user/fetchSubscriptionStatus',
  async (params, { dispatch, getState }) => {
    
    //Update the user data on the backend
    try{
		const { bulk }= params;
		let data= bulk; //pre-fetched via bulk api
	  if (!data){
			const { user } = getState();
			const apiName = 'CoreAPI2';
			const path = '/x2/subscriptionstatus/' + user.id;
			const options = {
			   headers: {},
			   response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
			};
	
	    data= await apiGet(apiName, path, options);
	  }
    return data;  
	}finally{
		dispatch(stopLoading());
	}
  }
);

export const fetchIdentificationStatus = createAsyncThunk(
  'user/fetchIdentificationStatus',
  async (params, { dispatch, getState }) => {
    
    try{
		const { bulk }= params;
		let data= bulk; //pre-fetched via bulk api
	    if (!data){
			const { user } = getState();
			const apiName = 'CoreAPI2';
			const path = '/x2/identityverificationstatus/' + user.id;
			const options = {
			   headers: {},
			   response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
			};
	
	        data= await apiGet(apiName, path, options);
	    }
        return data;  
	}finally{
		dispatch(stopLoading());
	}
  }
);

export const verifyNPI = createAsyncThunk(
  'user/verifyNPI',
  async ({npi}, { dispatch, getState }) => {
    
    //Update the user data on the backend
    try{
		const { user } = getState();
		const apiName = 'CoreAPI2';
		const path = '/x2/identityverificationstatus/' + user.id + '/verifynpi?npi=' + npi;
		const options = {
		   headers: {},
		   response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		};

     const data= await apiPut(apiName, path, options);
     return data;  
	}finally{
		dispatch(stopLoading());
	}
  }
);

export const verifyPracticeStates = createAsyncThunk(
  'user/verifyPracticeStates',
  async ({npi}, { dispatch, getState }) => {
    
    //Update the user data on the backend
    try{
		const { user } = getState();
		const apiName = 'CoreAPI2';
		const path = '/x2/identityverificationstatus/' + user.id + '/verifypracticestates';
		const options = {
		   headers: {},
		   response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		};

     const data= await apiPut(apiName, path, options);
     return data;  
	}finally{
		dispatch(stopLoading());
	}
  }
);


export const addFeaturedPost = createAsyncThunk(
  'user/addFeaturedPost',
  async (params, { dispatch, getState }) => {
    
    //Update the user data on the backend
    try{
		const { postId }= params;
		const { user } = getState();
		const apiName = 'CoreAPI2';
		const path = '/x2/userx/' + user.id + '/addfeaturedpost?postId=' + postId;
		const options = {
		   headers: {},
		   response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		};

        const data= await apiPut(apiName, path, options);
        return postId;  
	}finally{
		dispatch(stopLoading());
	}
  }
);

export const removeFeaturedPost = createAsyncThunk(
  'user/removeFeaturedPost',
  async (params, { dispatch, getState }) => {
    
    //Update the user data on the backend
    try{
		const { postId }= params;
		const { user } = getState();
		const apiName = 'CoreAPI2';
		const path = '/x2/userx/' + user.id + '/removefeaturedpost?postId=' + postId;
		const options = {
		   headers: {},
		   response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		};

        const data= await apiPut(apiName, path, options);
        return postId;  
	}finally{
		dispatch(stopLoading());
	}
  }
);

const updateUserInfoAPI = async (userId, userData) => {
	  const apiName = 'CoreAPI';
	  const path = '/user/' + userId;
	  const body = userData;
	  console.log(body);
      const options = {
		  headers: {},
		  body: body,
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

 	  return await apiPut(apiName, path, options);
};
  
const updateProviderInfoAPI = async (providerId, providerData) => {
	  const apiName = 'CoreAPI';
	  const path = '/providerinfo/' + providerId;
	  const body = providerData;
      const options = {
		  headers: {},
		  body: body,
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

 	  return await apiPut(apiName, path, options);
};

const fetchUserInfoAPI = async (userId) => {
	  const apiName = 'CoreAPI';
	  const path = '/user/' + userId;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

 	  return await apiGet(apiName, path, options);
};

export const logoutUser = () => async (dispatch, getState, subManager) => {
  const { user } = getState();

  if (!user.role || user.role.length === 0) {
    // already guest
    return null;
  }

  console.log("Logging out user in userSlice.");
  dispatch(setInitialSettings());
  subManager.unsubscribeAll();
  
  //Sentry
  Sentry.getGlobalScope().setUser(null);

  return dispatch(userLoggedOut());
};




export const signedUpUser = ({userId,email,channelId,referralRequest}) => async (dispatch, getState) => {
  if (channelId){
    
  }
  
  history.push({
    pathname: '/await-confirm?email='+email + (referralRequest ? '&referralRequest=true' : ''),
  });

};

export const confirmedUser = (email,channelId) => async (dispatch, getState) => {

  history.push({
    pathname: '/sign-in?email='+email + (channelId ? '&channelId=' + channelId : ''),
  });

};

export const requestedPasswordReset = (email) => async (dispatch, getState) => {

  history.push({
    pathname: '/reset-password?email='+email,
  });

};

export const forgotPasswordReset = (email) => async (dispatch, getState) => {

  history.push({
    pathname: '/sign-in?email='+email,
  });

};

export const loginRedirect = (channelId) => async (dispatch, getState) => {

if (channelId){
  history.push({
    pathname: '/group/' + channelId,
  });
}

};

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }

  jwtService
    .updateUserData(user)
    .then((userObj) => {
      dispatch(showMessage({ message: 'User data saved!' }));
    })
    .catch((error) => {
      dispatch(showMessage({ message: error.message }));
    });
};

const initialState = {
  id: '1', 
  role: [], // guest
  timelineId: '1',
  data: {
    screenName: 'Guest',
    firstName: 'Guest',
    email: 'guest',
    type: 'user',
    followerCount: 0,
    followingCount: 0,
    connectionCount: 0
  },
  images: {
    photoURL: 'assets/images/avatars/brian-hughes.jpg',
    headerImageURL: 'assets/images/pages/profile/cover.jpg',
  },
  shortcuts: [],
  sessionSettings: {
    welcomeMessage: true,
  },
  subscriptionStatus: {},
  identificationStatus: {},
  
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLoggedOut: (state, action) => initialState,
    setUserInitialized: (state, action) => ({...state, initialized: true}),
    updateSessionSettings: (state, action) => ({...state, sessionSettings: { ...state.sessionSettings, ...action.payload }}),
  },
  extraReducers: builder => {
    builder
    .addCase(updateUserSettings.fulfilled, (state, action) => {
      return action.payload;
    })
    .addCase(updateUserImage.fulfilled, (state, action) => {
      return action.payload;
    })
    .addCase(updateUserPhone.fulfilled, (state, action) => {
      state.data.phone= action.payload;
      state.data.phoneVerified= false;
    })
    .addCase(verifyUserPhone.fulfilled, (state, action) => {
      state.data.phoneVerified= true;
    })
    .addCase(updateUserMFA.fulfilled, (state, action) => {
      state.data.mfaEnabled= action.payload;
    })
    .addCase(updateUserInfo.fulfilled, (state, action) => {
      return action.payload;
    })
    .addCase(updateProviderInfo.fulfilled, (state, action) => {
      return action.payload;
    })
    .addCase(refreshUserInfo.fulfilled, (state, action) => {
      return action.payload;
    })
    .addCase(updateUserShortcuts.fulfilled, (state, action) => {
      return action.payload;
    })
    .addCase(setUser.fulfilled, (state, action) => {
      return action.payload;
    })
    .addCase(followUser.fulfilled, (state, action) => {
      state.data.followingCount= state.data.followingCount + 1;
    })
    .addCase(updateAcceptedTerms.fulfilled, (state, action) => {
      state.requireAcceptTerms= false;
    })
    .addCase(unfollowUser.fulfilled, (state, action) => {
      if (state.data.followingCount > 0) state.data.followingCount= state.data.followingCount - 1;
    })
    .addCase(addContactToConnections.fulfilled, (state, action) => {
      state.data.connectionCount= state.data.connectionCount + 1;
    })
    .addCase(removeConnection.fulfilled, (state, action) => {
      if (state.data.connectionCount > 0) state.data.connectionCount= state.data.connectionCount - 1;
    })
    .addCase(addFeaturedPost.fulfilled, (state, action) => {
      if (!state.data.featuredPosts.includes(action.payload)) state.data.featuredPosts.push(action.payload);
    })
    .addCase(removeFeaturedPost.fulfilled, (state, action) => {
      state.data.featuredPosts= state.data.featuredPosts.filter(function (postId) {
           return postId !== action.payload;
      });
    })
    .addCase(fetchSubscriptionStatus.fulfilled, (state, action) => {
      state.subscriptionStatus= action.payload;
    })
    .addCase(fetchIdentificationStatus.fulfilled, (state, action) => {
      state.identificationStatus= action.payload;
    })
    .addCase(verifyNPI.fulfilled, (state, action) => {
      state.identificationStatus= action.payload;
    })
    .addCase(verifyPracticeStates.fulfilled, (state, action) => {
      state.identificationStatus= action.payload;
    })
    .addCase(updateUserTags.fulfilled, (state, action) => {
      state.raw= action.payload;
    })
    
  },

});

export const { userLoggedOut, setUserInitialized, updateSessionSettings } = userSlice.actions;

export const selectUser = ({ user }) => user;

export const selectUserId = ({ user }) => { return user.id;}

export const selectUserShortcuts = ({ user }) => user.shortcuts;

export const selectSessionSettings = ({ user }) => user.sessionSettings;

export const selectUserRoles = ({ user }) => user.role;

export const selectSubscriptionStatus = ({ user }) => user.subscriptionStatus;

export const selectIdentificationStatus = ({ user }) => user.identificationStatus;

/* Profile completeness selectors  */
/***********************************/

export const selectAboutStatus = ({ user }) => {
	return !!user.data?.about;
}

export const selectProfessionalAddressStatus = ({ user }) => {
	return !!user.providerInfo?.address &&
	       !!user.providerInfo?.stateProvince &&
	       !!user.providerInfo?.city &&
	       !!user.providerInfo?.postalCode;
}

export const selectProfessionalEmailStatus = ({ user }) => {
	return !!user.providerInfo?.email;
}

export const selectClientTypeStatus = ({ user }) => {
	return !!user.providerInfo?.clientTypes?.length;
}

export const selectSpecializationsStatus = ({ user }) => {
	return !!user.providerInfo?.specializations?.length;
}

export const selectCredentialsStatus = ({ user }) => {
	return !!user.providerInfo?.credentials?.length;
}

export const selectPracticeStatesStatus = ({ user }) => {
	return !!user.providerInfo?.practiceStates?.length;
}

export const selectIdentityVerifiedStatus = ({ user }) => {
	return user.identificationStatus?.currentStatus == "Approved";
}

export const selectNPIVerifiedStatus = ({ user }) => {
	return user.identificationStatus?.npiStatus == "Approved";
}

export const selectStateLicensesVerifiedStatus = ({ user }) => {
	return user.identificationStatus?.stateLicenseStatus == "Verified";
}

export default userSlice.reducer;
