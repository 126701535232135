import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import fileTypes from './fileTypes.json';
import { amber, blue, green, grey, red, orange } from '@mui/material/colors';

const TypeBadge = styled(Box)(({ theme, ...props }) => ({
  backgroundColor: {
    PDF: red[600],
    DOC: blue[600],
    DOCX: blue[600],
    GDOC: blue[600],
    XLS: green[600],
    XLSX: green[600],
    GSHEET: green[600],
    PPT: orange[600],
    PPTX: orange[600],
    GSLIDE: orange[600],
    TXT: grey[600],
    RTF: grey[600],
    MD: grey[600],
    HTML: grey[600],
    JPG: amber[600],
    PNG: amber[600],
    GIF: amber[600],
    WEBP: amber[600],
    MP4: amber[600],
    MOV: amber[600],
    WMV: amber[600],
    MP3: amber[600],
    CSV: grey[600],
    FILE: grey[600],
  }[props.color],
}));


function getType(type){
	if (type === "folder") return type;
  const found = fileTypes.find((element) => element.type == type);
  const out= found ? found.label : 'FILE';
	return out;
}

function ItemIcon(props) {
  const type = getType(props.type);

  if (type === 'folder') {
    return (
      <FuseSvgIcon className="" size={56} color="disabled">
        heroicons-outline:folder
      </FuseSvgIcon>
    );
  }

  return (
    <div className="relative">
      <FuseSvgIcon className="" size={56} color="disabled">
        heroicons-outline:document
      </FuseSvgIcon>
      <TypeBadge
        color={type}
        className="absolute left-0 bottom-0 px-6 rounded text-12 font-semibold leading-20 text-white"
      >
        {type}
      </TypeBadge>
    </div>
  );
}

export default ItemIcon;
