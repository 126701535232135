import lazyLoad from 'app/shared-components/util/lazyLoad';

const SearchApp = lazyLoad(() => import('./SearchApp'));
const ProvidersByState = lazyLoad(() => import('./ProvidersByState'));
const ProvidersStates = lazyLoad(() => import('./ProviderStates'));

const PublicSearchProvidersConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: true,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: null, //anyone
  routes: [
    {
      path: '/public/search',
      element: <SearchApp />,
    },
    {
      path: '/public/search/providers',
      element: <SearchApp />,
    },
    {
      path: '/public/search/programs',
      element: <SearchApp />,
    },
    {
      path: '/public/search/posts',
      element: <SearchApp />,
    },
    {
      path: '/public/search/providers/states',
      element: <ProvidersStates isPublic />,
    },
    {
      path: '/public/search/providers/state/:state',
      element: <ProvidersByState isPublic />,
    },
  ],
};

export default PublicSearchProvidersConfig;


