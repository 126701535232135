import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';



export const buildBulkRequest= (...items) => {  
  return {items: items}
};

export const buildGetItem= (key, path, pathParams, queryParams) => {  
  return {key: key, path: path, pathParameters: pathParams, queryParameters: queryParams}
};

export const bulkApiGet= async (params) => {  
  let url= '/bulk/get';
  const apiName = 'CoreAPI';
  const path = url;
  const options = {
	  headers: {},
	  body: params,
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  let data= await apiPut(apiName, path, options);
  return data;
};


export const bulkLoginCalls= async ({user}) => { 
	
  const subscriptionStatus= buildGetItem('subscription', '/x2/subscriptionstatus/' + user.id, {id: user.id}); 
  const userTimeline= buildGetItem('userTimeline', '/channel/' + user.timelineId, {id: user.timelineId}); 
  const systemConfig= buildGetItem('systemConfig', '/systemconfig/1', {id: '1'}); 
  const groups= buildGetItem('groups', 
                             '/user/' + user.id + '/channelmember/channels?role=STARTS:group', 
                             {id: user.id}, {role: 'STARTS:group'}); 
  const teams= buildGetItem('teams', 
                             '/user/' + user.id + '/channelmember/channels?role=STARTS:team', 
                             {id: user.id}, {role: 'STARTS:team'});      
  const organizations= buildGetItem('organizations', 
                             '/user/' + user.id + '/channelmember/channels?role=STARTS:organization', 
                             {id: user.id}, {role: 'STARTS:organization'});                        
  const clients= buildGetItem('clients', '/client/byowner/' + user.id, {ownerId: user.id}); 
  const connections= buildGetItem('connections', '/user/' + user.id + '/userconnection/pairs', {id: user.id}); 
  const messages= buildGetItem('messages', '/externalmessage/byowner/' + user.id, {ownerId: user.id}); 
  const referrals= buildGetItem('referrals', '/outboundreferral/byowner/' + user.id + '/?status=STARTS:open',
                                 {ownerId: user.id}, {status: 'STARTS:open'}); 
  const referralInterests= buildGetItem('referralInterests', '/user/' + user.id + '/referralinterest/pairs?status=STARTS:open',
                                 {id: user.id}, {status: 'STARTS:open'} ); 
  const authorizedClients= buildGetItem('authorizedClients', '/user/' + user.id + '/clientauthorizeduser/pairs', {id: user.id}); 

  const bulkCall=  buildBulkRequest(userTimeline, groups, teams, clients, organizations,
                                    connections, messages, referrals, systemConfig,
                                    referralInterests, authorizedClients, subscriptionStatus);

  return await bulkApiGet(bulkCall);
};


export const bulkReferralListCalls= async ({referrals, status}) => { 
	let items= [];
	for (let i in referrals) {
		const referral= referrals[i];
		items.push(buildGetItem(referral.id, '/outboundreferral/' + referral.id + '/referralinterest/pairs' + (status ? '?status=STARTS:' + status : ""),
		                        {id: referral.id}, status ? {status: 'STARTS:' + status} : undefined)); 
	}
	return await bulkApiGet({items: items});
}

export const bulkGroupListCalls= async ({channels}) => { 
	let items= [];
	for (let i in channels) {
		const channel= channels[i];
		items= items.concat(buildLoadGroupCalls(channel));
	}
	
	return await bulkApiGet({items: items});
}

export const buildLoadGroupCalls= (channel) => { 
	
  const topics= buildGetItem(channel.id + 'topics', 
                             '/channel/byparent/' + channel.id + '?channelType=STARTS:topic', 
                             {parentId: channel.id}, {channelType: 'STARTS:topic'}); 
  const threads= buildGetItem(channel.id + 'threads', 
                             '/channel/byroot/' + channel.id + '?channelType=STARTS:thread', 
                             {rootId: channel.id}, {channelType: 'STARTS:thread'}); 
  const members= buildGetItem(channel.id + 'members', 
                             '/channel/' + channel.id + '/channelmember/users', 
                             {id: channel.id});                            
  const clients= buildGetItem(channel.id + 'clients', 
                             '/channel/' + channel.id + '/clientteam/pairs', 
                             {id: channel.id}, {role: 'EQ:admin'});   
                             
  if (channel.channelType === 'team') return [topics, threads, members, clients];
  else return [topics, threads, members];
  
};

export const bulkClientListCalls= async ({clients}) => { 
	let items= [];
	for (let i in clients) {
		const client= clients[i];
		items= items.concat(buildLoadClientCalls(client));
	}
	
	return await bulkApiGet({items: items});
}

export const buildLoadClientCalls= (client) => { 
  const teams= buildGetItem(client.id + 'teams', 
                             '/client/' + client.id + '/clientteam/pairs', 
                             {id: client.id}); 
  const users= buildGetItem(client.id + 'users', 
                             '/client/' + client.id + '/clientauthorizeduser/pairs', 
                             {id: client.id});                                               

  return [teams, users];
};
