import lazyLoad from 'app/shared-components/util/lazyLoad';

const ProgramProfile = lazyLoad(() => import('app/shared-components/organization/ProgramProfile'));
const LocationProfile = lazyLoad(() => import('app/shared-components/organization/LocationProfile'));


const OrgProfileAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/program/:programId',
      element: <ProgramProfile />,
    },
    {
      path: '/location/:locationId',
      element: <LocationProfile />,
    }
  ],
};

export default OrgProfileAppConfig;
